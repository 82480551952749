<template>
  <button class="end icon-button" @click="togglePassword">
    <EyeOpenIcon v-if="visible" />
    <EyeClosedIcon v-else />
  </button>
</template>

<script>
import EyeOpenIcon from '../icons/eye-open.vue'
import EyeClosedIcon from '../icons/eye-closed.vue'

export default {
  components: { EyeOpenIcon, EyeClosedIcon }, 
  props: {
    inputId: { type: String, required: true },
  },
  data: function(){
    return {
      visible: false
    };
  },
  computed: {
    input() {
      return document.querySelector(`#${this.inputId}`);
    },
    icon() {
      return document.querySelector(`button img.inline-icon`);
    },
  },
  methods: {
    togglePassword() {
      if (this.input.type === 'password') {
        this.input.type = 'text';
        this.visible = true;
      } else if (this.input.type === 'text') {
        this.input.type = 'password';
        this.visible = false;
      }
    },
  },
};
</script>
