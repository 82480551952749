<template>
  <router-view></router-view>
</template>

<script>
import axios from 'axios';
import { baseUrl, graphqlApiUrl } from './utilities/apiUrls';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'App',
  computed: {
    ...mapGetters(['sdkContext']),
  },
  methods: {
    ...mapActions([
      'tokenIsSet',
      'setChargeData',
      'setPublicKey',
      'getBanks',
      'setMeta',
      'setConnectData',
    ]),
    generateEncryptionKey() {
      let count = 1;
      let result = '';

      while (count < 3) {
        result += Math.random()
          .toString(36)
          .slice(2);
        count++;
      }

      return result.toUpperCase();
    },
    setEncryptionKey() {
      let encryptionKey = localStorage.getItem('csek');
      if (!encryptionKey) {
        encryptionKey = this.generateEncryptionKey();
        localStorage.setItem('csek', encryptionKey);
      }
    },
    setupMeta(meta) {
      const { environment, publicKey, siteName } = meta;
      window['SITE_NAME'] = siteName;
      this.setMeta(meta);

      window.restAPI = axios.create({ baseURL: baseUrl(environment) });
      axios.defaults.baseURL = graphqlApiUrl(environment);
      axios.defaults.headers.common['Authorization'] = `Bearer ${publicKey}`;

      this.tokenIsSet();

      this.loadFont(meta);
      this.setupStyles(meta);
    },
    updateCsek(csek) {
      localStorage.setItem('csek', csek);
    },
    loadFont(meta) {
      if (typeof meta?.styles?.font === "object") {
        const { name, url } = meta.styles?.font;
        const font = new FontFace(name, `url(${url})`);
        font
          .load()
          .then(function (loaded_face) {
            document.fonts.add(loaded_face);
            document.body.style.fontFamily = `"${name}", Manrope`;
          })
          .catch(function (error) {
            console.log("font error", error);
          });
      }
    },
    setupStyles(meta){
       if (typeof meta?.styles?.colors === "object") {
        document.documentElement.style.setProperty(
          "--primary-color",
          meta.styles.colors.primary
        );
        document.documentElement.style.setProperty(
          // Value should be of the form: `primaryRgb = "255, 120, 30";`
          "--primary-color-rgb",
          meta.styles.colors.primaryRgb
        );
        document.documentElement.style.setProperty(
          "--badge-color",
          `${meta.styles.colors.primary}11`
        );
        document.documentElement.style.setProperty(
          "--text-color",
          meta.styles.colors.text
        );
      }
    },
  },
  async created() {
    this.setEncryptionKey();
    this.listen('sherlock:init', this.setupMeta);
    this.listen('sherlock:update-csek', this.updateCsek);
    this.listen('sherlock:chargedata', this.setChargeData);
    this.listen('sherlock:connectdata', this.setConnectData);
  },
};
</script>

<style lang="scss">
@import '@/styles/app.scss';
</style>
