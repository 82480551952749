<template>
  <div>
    <label class="small grey" :for="data.identifier">{{ data.name }}</label>
    <div :class="{ 'input-container': data.type === 'password' }">
      <input
        class="input"
        :autocapitalize="
          data.name.toLowerCase().includes('email address') ? 'none' : 'on'
        "
        :id="data.identifier"
        :class="data.identifier"
        :type="data.type"
        :inputmode="
          data.name.toLowerCase().includes('transaction pin') ||
          inputmode === 'numeric'
            ? 'numeric'
            : 'text'
        "
        :disabled="disabled"
        v-model="value"
        @change="handleChange"
      />
      <password-toggle
        v-if="data.type === 'password' && showPasswordToggle"
        :inputId="data.identifier"
      />
    </div>
    <p
      class="tooltip extra-small flex items-center"
      v-if="tooltip.length > 0"
    >
      <svg class="mr-s" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.0001 0.600098C10.5361 0.600098 13.4001 3.4641 13.4001 7.0001C13.4001 10.5361 10.5361 13.4001 7.0001 13.4001C3.4641 13.4001 0.600098 10.5361 0.600098 7.0001C0.600098 3.4641 3.4641 0.600098 7.0001 0.600098ZM7.8001 3.8001C7.8001 3.3601 7.4401 3.0001 7.0001 3.0001C6.5601 3.0001 6.2001 3.3601 6.2001 3.8001C6.2001 4.2401 6.5601 4.6001 7.0001 4.6001C7.4401 4.6001 7.8001 4.2401 7.8001 3.8001ZM7.8001 11.0001V6.2001H6.2001V11.0001H7.8001Z" fill="#2F3134"/>
      </svg>
      <span v-html="tooltip" class="flex-1"></span>
    </p>
  </div>
</template>

<script>
import PasswordToggle from './PasswordToggle.vue';

export default {
  props: {
    data: { type: Object, required: true },
    disabled: { type: Boolean, required: false, default: false },
    tooltip: { type: String, required: false, default: '' },
    inputmode: { type: String, required: false, default: '' },
    slug: { type: String, required: false, default: '' },
  },
  computed: {
    showPasswordToggle() {
      return !['chippercash'].includes(this.slug);
    },
  },
  data() {
    return {
      value: '',
    };
  },
  components: { PasswordToggle },
  methods: {
    handleChange() {
      this.$emit('input', this.value);
    },
  },
};
</script>

<style scoped>
.tooltip {
  line-height: 1.4;
  margin-top: 10px;
  padding: 10px;
  background: rgba(47, 49, 52, 0.03);
  border-radius: 10px;
}
</style>
