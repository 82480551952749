export default {
  data() {
    const states = this.$options.states;
    let stateData = {};

    if (states) {
      stateData = {
        currentState: states[0],
      };
    }

    return {
      ...stateData,
    };
  },
  beforeCreate() {
    const states = this.$options.states;
    if (states) {
      this.$options.computed = this.$options.computed || {};

      states.forEach((state) => {
        this.$options.computed[state] = function() {
          return this.currentState == state;
        };
      });
    }
  },
};
