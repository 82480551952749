<template>
  <div>
    <h2 class="h2" v-if="title">{{ title }}</h2>
    <div v-if="description" :class="`flex items-center ${title && img ? 'mt-s' : ''}`">
      <div class="icon-container mr-s" v-if="img">
        <img
          :src="img.src"
          :alt="img.alt || 'bank_logo'"
          :class="img.class || 'icon round'"
        />
      </div>
      <p :class="description.class || 'grey description'" v-html="description.text || description"></p>
    </div>
    <p class="small caption grey" v-if="caption">{{ caption }}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, required: false },
    description: { type: [String, Object], required: false, },
    img: { type: Object, required: false, },
    caption: { type: String, required: false, },
  }
};
</script>

<style scoped>
  .caption {
    line-height: 1.4;
    margin-top: 8px;
  }
  .description{
    font-size: 14px;
    line-height: 1.2;
  }
</style>
