const eventMethod = window.addEventListener
? "addEventListener"
: "attachEvent";
const eventer = window[eventMethod];
const messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

export default {
  methods: {
    listen(identifier, callback) {
      eventer(
        messageEvent,
        (e) => {
          const data = e.data;
          if (typeof data === "string" && data.indexOf(identifier) > -1) {
            const contentString = data.replace(`${identifier}:`, "");
            try {
              const content = JSON.parse(contentString);
              callback(content);
            } catch (err) {
              callback(contentString);
            }
          }
        },
        false
      );
    },
    onTokenSet(callback) {
      this.$root.$on("token-set", () => {
        callback();
      });
    },
  },
}