// Get data attributes that aren't data-analytics-event
const getDataAttributes = (element) => {
    const dataAttributes = element.dataset;
    delete dataAttributes.analyticsEvent;

    const transformedProperties = Object.keys(dataAttributes).reduce(
        (newObj, key) => ({
            ...newObj,
            [key.toLowerCase().substring(9)]: dataAttributes[key],
        }),
        {}
    );
    return transformedProperties;
};

export default getDataAttributes;
