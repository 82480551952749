const baseUrls = {
  development: 'https://sherlock-staging.onrender.com',
  production: 'https://api.sendcashpay.com',
};

export function baseUrl(environment) {
  if (environment !== 'production') {
    return baseUrls['development'];
  }

  return baseUrls[process.env.NODE_ENV];
}

export function graphqlApiUrl(environment = 'production') {
  return `${baseUrl(environment)}/query`;
}
