<template>
    <div class="flex direction-column flex-1">
        <div class="modal__main sm:px">
            <header class="mb">
                <modal-title
                    v-if="sdkContext === CHECKOUT"
                    title="Select Bank Account"
                    description="Choose bank account to make payment from or add a new one"
                />
                <modal-title
                    v-else
                    title="Link Bank Account"
                    :description="`You can link as many bank accounts as you want to ${meta.siteName}`"
                />
            </header>
            <div v-if="fetching" class="sm:px py-s">
                <loader>This may take up to 30 seconds</loader>
            </div>
            <ul v-else class="list--accounts">
                <li class="flex-layout-center-between" v-for="(account, index) in accounts" :key="index">
                    <div class="flex-1">
                        <button
                            class="button--account"
                            @click="payWithAccount(index)"
                            data-analytics-event="Selected bank to debit"
                        >
                            <div class="flex-layout-center-between items-center">
                                <div class="flex items-center flex-1">
                                    <img class="icon mr-s" :src="account.bankLogo" alt="bank_logo" />
                                    <div class="text-left">
                                        <p class="extra-small link-button strong" v-if="account.defaultAccount">Default</p>
                                        <h3 class="text-bold strong text-md">{{ account.bankName}}</h3>
                                        <p class="small alternate-grey">{{ account.accountNumber }}</p>
                                    </div>
                                </div>
                                <p class="alternate-grey text-right mr-s">{{ account.balance }}</p>
                            </div>
                        </button>
                    </div>

                    <div>
                        <button :ref="`show-action-btn-${index}`" class="plain flex items-center px-s py" @click="toggleListNav(index)"> 
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <title>Show actions</title>
                                <path d="M9.5 5.72705C10.1213 5.72705 10.625 5.22337 10.625 4.60205C10.625 3.98073 10.1213 3.47705 9.5 3.47705C8.87868 3.47705 8.375 3.98073 8.375 4.60205C8.375 5.22337 8.87868 5.72705 9.5 5.72705Z" fill="black"/>
                                <path d="M9.5 10.2271C10.1213 10.2271 10.625 9.72337 10.625 9.10205C10.625 8.48073 10.1213 7.97705 9.5 7.97705C8.87868 7.97705 8.375 8.48073 8.375 9.10205C8.375 9.72337 8.87868 10.2271 9.5 10.2271Z" fill="black"/>
                                <path d="M9.5 14.7271C10.1213 14.7271 10.625 14.2234 10.625 13.6021C10.625 12.9807 10.1213 12.4771 9.5 12.4771C8.87868 12.4771 8.375 12.9807 8.375 13.6021C8.375 14.2234 8.87868 14.7271 9.5 14.7271Z" fill="black"/>
                            </svg>
                        </button>
                    </div>
                    <div 
                        v-if="showListNav.index === index && showListNav.value" 
                        v-click-outside="{
                            exclude: [`show-action-btn-${index}`],
                            handler: 'toggleListNav'
                        }"
                        class="action-nav px-s py-s"
                    >
                        <button
                            class="plain small py-xs strong border-bottom"
                            @click="setAccountAsDefault(index)"
                            :disabled="busy || account.defaultAccount"
                            v-if="sdkContext === CONNECT"
                        >
                            Set as Default
                        </button>
                        <button
                            class="plain small py-xs strong"
                            @click="removeAccount(index)"
                            :disabled="busy"
                        >
                            Delete
                        </button>
                    </div>
                </li>
            </ul>

            <button
                class="button button--plain mt"
                @click="showScreen('unlinked_accounts')"
                data-analytics-event="Clicked 'Reconnect an unlinked bank account'"
            >
                Reconnect an unlinked bank account
            </button>

            <button
                @click="showScreen('bank_select')"
                data-analytics-event="Clicked 'Add new bank account'"
                class="button button--secondary mt-s"
            >
                Add a new bank account
            </button>
        </div>
        <div class="modal__footer flex justify-center p mb" v-if="sdkContext === CONNECT">
            <button class="button" @click="closeConnectModal(accounts)">
                I'm done
            </button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { fetchPassword, login, logout, fetchConnections, setDefaultAccount } from '../utilities/api';
import { decrypt, encryptWithPublicKey } from '../utilities/crypto';
import formatMoney from '../utilities/formatMoney';
import { CONNECT, CHECKOUT } from '../utilities/sdkContext';
import ModalTitle from './shared/ModalTitle.vue';

export default {
    components: { ModalTitle },
    states: ['idle', 'fetching', 'busy'],
    computed: {
        ...mapGetters(['chargeData', 'publicKey', 'banks', 'accounts', 'connectData', 'sdkContext', 'meta']),
        noLinkedAccounts() {
            return this.idle && this.accounts.length === 0;
        },
        amountToPay() {
            return formatMoney(this.chargeData.amount);
        },
        sdkData() {
            return this.sdkContext == CHECKOUT ? this.chargeData : this.connectData;
        },
        currentDefaultAccount() {
            return this.accounts.find((account) => account.defaultAccount === true);
        },
    },
    data() {
        return {
            retries: {},
            CONNECT,
            CHECKOUT,
            pageToMoveTo: 'bank_select',
            showListNav: {
                index: 0,
                value: false,
            },
        };
    },
    methods: {
        ...mapActions(['clearAccounts', 'addAccount', 'setSelectedAccount']),
        async getAccounts() {
            this.clearAccounts();
            this.currentState = 'fetching';

            const { userId } = this.sdkData;

            if (this.sdkContext === CHECKOUT) {
                const { body: loginDetails } = await fetchPassword(userId);

                if (loginDetails && loginDetails.length > 0) {
                    await Promise.all(loginDetails.map((loginDetail) => this.retrieveAccount(loginDetail)));
                    this.currentState = 'idle';
                    if (this.accounts.length === 0) this.skipAccountSelection();
                } else {
                    this.currentState = 'idle';
                    this.skipAccountSelection();
                }
            } else if (this.sdkContext === CONNECT) {
                const { body: accounts } = await fetchConnections(userId);
                this.currentState = 'idle';

                for (let index = 0; index < accounts.length; index++) {
                    const { id, username, slug, defaultAccount, accountNumber, accountName, balance } = accounts[index];

                    const bank = this.getBank(slug);
                    if (!bank) continue;

                    this.accounts.push({
                        id,
                        accountNumber,
                        accountName,
                        balance: formatMoney(balance),
                        balanceAmount: balance,
                        bankLogo: bank.logoUrl,
                        bankName: bank.name,
                        slug: slug,
                        username,
                        defaultAccount,
                    });
                }
            }
        },
        async retrieveAccount(loginDetails, isFirstAttempt = false) {
            const { username, slug } = loginDetails;
            let password = loginDetails.password;
            if (slug !== 'abeg') {
                password = await decrypt(loginDetails.password);
            }
            if (!password || password.length == 0) return this.disconnectAccount(loginDetails);

            const publicKeyEncryptedPassword = await encryptWithPublicKey(password, this.publicKey);

            const { success, errorCode, errorMessage, body } = await login({
                username,
                slug,
                userId: this.chargeData.userId,
                password: publicKeyEncryptedPassword,
                passwordForKeeps: loginDetails.password,
                reconnectDisconnectedAccounts: isFirstAttempt,
            });

            if (!success) {
                if (errorCode === 'GTB_ERROR' || errorCode === 'BANK_ERROR') {
                    if (!this.retries[username]) this.retries[username] = 0;

                    if (this.retries[username] < 3) {
                        this.retries[username]++;
                        return this.retrieveAccount(loginDetails, isFirstAttempt);
                    } else return this.disconnectAccount(loginDetails);
                } else {
                    this.$toasted.error(errorMessage);
                    return this.disconnectAccount(loginDetails);
                }
            }

            const accounts = body.bankLoginConnection;
            for (let index = 0; index < accounts.length; index++) {
                const account = accounts[index];
                const bank = this.getBank(slug);
                if (!bank) continue;

                this.accounts.push({
                    id: this.sdkData.userId,
                    accountName: account.accountName,
                    accountNumber: account.accountNumber,
                    balance: formatMoney(account.balance),
                    balanceAmount: account.balance,
                    bankLogo: bank.logoUrl,
                    bankName: bank.name,
                    slug: bank.slug,
                    password: publicKeyEncryptedPassword,
                    username,
                });
            }
        },
        getBank(slug) {
            return this.banks.find((b) => b.slug === slug);
        },
        disconnectAccount({ username, slug, accountNumber }) {
            return logout({
                userId: this.chargeData.userId,
                username,
                slug,
                accountNumber,
                transfersType: this.sdkContext,
            });
        },
        payWithAccount(index) {
            if (this.sdkContext === CONNECT) return;

            const { balanceAmount } = this.accounts[index];
            if (balanceAmount > this.chargeData.amount) {
                this.setSelectedAccount(index);
                this.$root.$emit('continue', { page: 'pay' });
            } else if (balanceAmount == this.chargeData.amount) {
                this.$toasted.info(
                    'Your balance will be insufficient for this transaction if this bank charges a transaction fee.'
                );
            } else this.$toasted.info('Your account balance is insufficient for this transaction.');
        },
        skipAccountSelection() {
            this.$navigation.push('Root');
            this.$navigation.clear('Root');
        },
        async removeAccount(index) {
            this.currentState = 'busy';

            const { id: userId, slug, username, accountNumber } = this.accounts[index];
            const { success, errorMessage } = await logout({
                userId,
                slug,
                username,
                accountNumber,
                transfersType: this.sdkContext,
            });
            if (success) this.accounts.splice(index, 1);
            else this.$toasted.error(errorMessage);

            this.currentState = 'idle';
        },
        async setAccountAsDefault(index) {
            this.currentState = 'busy';

            const { id: userId, slug, username, accountNumber } = this.accounts[index];
            const { success, body: setAsDefault } = await setDefaultAccount({
                userId,
                slug,
                username,
                accountNumber,
            });
            if (success && setAsDefault) {
                if (this.currentDefaultAccount) this.currentDefaultAccount.defaultAccount = false;

                this.accounts[index].defaultAccount = true;
                this.$toasted.success('Account successfully set as default.');
            } else this.$toasted.error('Something went wrong. Please retry.');

            this.currentState = 'idle';
        },
        showScreen(screen) {
            this.pageToMoveTo = screen;
            this.$navigation.push('Root');
        },
        toggleListNav(index) {
            if(index === null) {
               this.showListNav = {
                    index: this.showListNav.index,
                    value: false
                }; 
            } else {
                this.showListNav = {
                    index,
                    value: !this.showListNav.value
                };
            }
        }
    },
    mounted() {
        this.$navigation.register({
            modal: 'Root',
            page: 'Accounts',
            push: () => {
                this.$root.$emit('continue', { page: this.pageToMoveTo });
            },
            pop: () => {},
        });

        this.getAccounts();
    },
    directives: {
        'click-outside': {
            bind: (el, binding, vnode) => {
                el.clickOutsideEvent = (event) => {
                    event.stopPropagation();
                    const { exclude, handler } = binding.value;
                    let clickedOnExcludedEl = false
                    exclude.forEach(refName => {
                        if (!clickedOnExcludedEl) {
                            const [excludedEl] = vnode.context.$refs[refName]
                            clickedOnExcludedEl = excludedEl?.contains(event.target) || excludedEl?.parentElement?.contains(event.target) 
                        }
                    })
                    if (!el.contains(event.target) && !clickedOnExcludedEl) {
                        vnode.context[handler](null)
                    }
                };
               
                document.addEventListener("click", el.clickOutsideEvent);
                document.addEventListener("touchStart", el.clickOutsideEvent);
               
            },
            unbind: (el) => {
                document.removeEventListener("click", el.clickOutsideEvent);
                document.removeEventListener("touchStart", el.clickOutsideEvent);
            },
        }
    }
};
</script>
