<template>
    <div class="page-consent">
        <div class="flex direction-column flex-1" v-if="sdkContext && meta">
            <div class="modal__main sm:px">
                <div class="page-consent__header">
                    <img
                        :src="meta.siteLogo"
                        ref="brandLogo"
                        width="auto"
                        :alt="`${meta.siteName || ''} logo`"
                        class="page-consent__header__image mx-auto"
                    />
                    <h1 class="text-center h3 mt mb-xs">Deposit with Sendcash Pay</h1>
                    <p class="page-consent__header__description mb-l">
                        {{
                            meta.consentText
                                ? meta.consentText
                                : `${meta.siteName || ''} uses Sendcash Pay to link and debit your bank account.`
                        }}
                    </p>
                </div>
                <ul v-if="sdkContext === CHECKOUT" class="list--consent">
                    <li class="mb flex">
                        <div class="page-consent__list-icon">
                            <LockIcon />
                        </div>
                        <div>
                            <h3 class="mb-xs">Secure</h3>
                            <p class="page-consent__list-content">
                                Encryption helps protect your personal financial data from all parties
                            </p>
                        </div>
                    </li>
                    <li class="mb flex">
                        <div class="page-consent__list-icon">
                            <EyeClosedIcon />
                        </div>
                        <div>
                            <h3 class="mb-xs">Private</h3>
                            <p class="page-consent__list-content">
                                Your credentials will never be made accessible to Sendcash Pay or
                                {{ meta.siteName }}
                            </p>
                        </div>
                    </li>
                </ul>
                <ul v-if="sdkContext === CONNECT" class="list-consent">
                    <li class="mb flxe">
                        <div class="page-consent__list-icon">
                            <LockIcon />
                        </div>
                        <div>
                           <h3 class="mb-xs">Secure</h3>
                           <p class="page-consent__list-content">
                              Top-of-the-line encryption helps protect your personal financial data from unauthorised
                              access.
                          </p>
                        </div>
                    </li>
                    <li>
                        <div class="page-consent__list-icon">
                            <EyeClosedIcon />
                        </div>
                        <div>
                          <h3 class="mb-xs"> You're in control</h3>
                          <div class="page-consent__list-content">
                            <p class="mb-s">{{ meta.siteName }} will not be able to see or ever access your credentials.</p>
                            <p>
                                Neither Sendcash Pay nor {{ meta.siteName }} can initiate a transaction without your
                                explicit approval.
                            </p>
                          </div>
                        </div>
                        
                    </li>
                </ul>
            </div>

            <div class="modal__footer flex items-center direction-column">
                <button
                    class="button mb"
                    @click="handleContinue()"
                    :disabled="!isTokenSet"
                    data-analytics-event="Clicked Continue"
                    data-analytics-page="consent"
                >
                    Continue
                </button>
            </div>
            <div class="flex justify-center px-xl">
                <small class="text-center grey"
                    >By clicking "Continue", you agree to the
                    <a
                        class=""
                        :href="meta.privacyPolicyUrl || 'https://sendcashpay.com/privacy'"
                        target="_blank"
                        rel="noopener noreferrer"
                        >{{ meta.privacyPolicyUrl ? meta.siteName : 'Sendcash Pay' }} End User Privacy Policy</a
                    >
                </small>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { CONNECT, CHECKOUT } from '../utilities/sdkContext';
import LockIcon from './icons/lock.vue';
import EyeClosedIcon from './icons/eye-closed.vue';

export default {
    components: { LockIcon, EyeClosedIcon },
    computed: {
        ...mapGetters(['isTokenSet', 'meta', 'sdkContext']),
    },
    data() {
        return {
            CONNECT,
            CHECKOUT,
        };
    },
    methods: {
        handleContinue(){
            localStorage.setItem('viewedConsent', '1')
            this.$navigation.push('Root')
        }
    },
    mounted() {
        this.$navigation.register({
            modal: 'Root',
            page: 'Consent',
            push: () => {
                this.$navigation.clear('Root');
                this.$root.$emit('continue', { page: 'accounts' });
            },
            pop: () => {},
        });
    },
    updated() {
      const viewedConsent = localStorage.getItem('viewedConsent');
      if(viewedConsent === "1"){
          this.$navigation.clear('Root');
          this.$root.$emit('continue', { page: 'accounts' });
      }
  }
};
</script>
