import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { baseUrl } from '@/utilities/apiUrls';

export async function trackEvents(data) {
    const url = baseUrl(process.env.NODE_ENV) + '/analytics';
    let spaySegmentTrackingId;
    spaySegmentTrackingId = localStorage.getItem('spaySegmentTrackingId');
    if (!spaySegmentTrackingId) {
        spaySegmentTrackingId = uuidv4();
        localStorage.setItem('spaySegmentTrackingId', spaySegmentTrackingId);
    }
    try {
        const properties = {
            name: document.title,
            path: document.location.pathname,
            referrer: document.referrer,
            title: document.title,
            url: document.location.href,
            userAgent: navigator.userAgent,
            merchant: window['SITE_NAME'] || '',
            platform: window.ReactNativeWebView ? 'mobile' : 'web',
            ...data.properties,
        };
        await axios.post(url, JSON.stringify({ type: data.type, user_id: spaySegmentTrackingId, properties }), {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    } catch (err) {
        console.log(err);
        return {
            success: false,
            errorCode: 'SERVICE_UNAVAILABLE',
            errorMessage: "We're not sure what went wrong here. Please try again.",
            body: null,
        };
    }
}
