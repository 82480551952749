<template>
  <div class="modal__main">
    <header class="mb sm:px fixed-header">
      <label for="search_bank">
        <span class="h2">Select Bank</span>
      </label>
      <input
        type="search"
        id="search_bank"
        ref="queryInput"
        class="input mt-s"
        placeholder="Search"
        @keyup="handleChange"
        @blur="trackBankSearch"
      />
    </header>
    <ul class="list list--clickable list--small">
      <li v-for="(bank) in searchResults" :key="bank.nubanCode">
        <button class="my-s" @click="signInToBank(bank.nubanCode, bank.name)">
          <span class="icon-container mr-s">
            <img class="icon no-mx" :src="bank.logoUrl" />
          </span>

          <span class="flex-1 h3 flex items-center">
            <span class="mr">
              {{ bank.name }}
            </span>
            <small v-if="bank.recommended" class="pill">
              <i>recommended</i>
            </small>
          </span>
        </button>
      </li>
      <p class="sm:px mt grey">
        Can't find your bank? We're adding support for more banks soon.
      </p>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {trackEvents} from "@/utilities/analytics";

const isRecommended = {
  'kuda': false,
  'zenith': false,
  'gtbank': false,
  'access': false,
  'wema': true,
  'chippercash': false,
  'diamond': false,
  'fcmb': false,
  'fidelity': false,
  'fbn': false,
  'gtworld': false,
  'gomoney': true,
  'abeg': true,
  'providusIBS': false,
};

export default {
  computed: {
    ...mapGetters(['banks', 'selectedBank']),
    searchResults() {
      const filteredBanksList = {
        recommended: [],
        others: [],
      };

      this.banks.forEach((bank) => {
        if (!bank.name.toLowerCase().includes(this.query.toLowerCase())) {
          return;
        }

        // @todo The recommended property should come from the backend. (Follow up with BE/PM to drive this update).
        // This will allow us do stuff like updating what's recommended via a retool.
        // So ideally this line should look like:
        // if (bank.recommended) {
        if (isRecommended[bank.slug]) {
          bank.recommended = true;
          filteredBanksList.recommended.push(bank);
        } else {
          bank.recommended = false;
          filteredBanksList.others.push(bank);
        }
      });

      return [
        ...filteredBanksList.recommended,
        ...filteredBanksList.others,
      ];
    },
  },
  data() {
    return {
      query: '',
    };
  },
  methods: {
    ...mapActions(['setSelectedBank']),
    signInToBank(nubanCode, bankName) {
      this.setSelectedBank(nubanCode);
      trackEvents({
        type: 'action',
        properties: { event: "selected bank to link", bankName}
      })
      this.$navigation.push('Root');
    },
    trackBankSearch() {
      trackEvents({
        type: 'action',
        properties: { event: "search bank list"}
      })
    },
    handleChange(evt) {
      this.query = evt.target.value;
    }
  },
  mounted() {
    this.$navigation.register({
      modal: 'Root',
      page: 'BankSelect',
      push: () => {
        if (this.selectedBank.preSetupForm.length > 0) {
          this.$root.$emit('continue', { page: 'pre_setup' });
        } else {
          this.$root.$emit('continue', { page: 'provide_credentials' });
        }
      },
      pop: () => this.$root.$emit('continue', { page: 'accounts' }),
    });

    this.$nextTick(() => this.$refs.queryInput.focus());
  },
};
</script>

<style lang="scss" scoped>
input[type="search"] {
  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 1em;
    width: 1em;
    border-radius: 50em;
    background: url("../assets/cancel.svg") no-repeat 50% 50%;
    background-size: contain;
    opacity: 0;
    pointer-events: none;
  }

  &:focus {
    &::-webkit-search-cancel-button {
      opacity: .7;
      pointer-events: all;
      cursor: pointer;
    }
  }
}

li {
  &:hover {
    cursor: pointer;
  }

  img {
    width: 48px;
    height: 48px;
    background: rgb(237, 237, 237);
    border-radius: 50%;
  }
}
</style>
