<template>
  <div class="flex direction-column flex-1">
    <div class="modal__main">
      <header class="mb sm:px">
        <modal-title
          title="Reconnect A Bank Account"
          description="You can reconnect all of your previously unlinked bank accounts"
        />
      </header>
      <ul class="list list--clickable list--small">
        <li v-if="!idle">
          <div class="sm:px py-s">
            <loader v-if="fetching">This may take up to 30 seconds</loader>
            <loader v-if="relinking">Relinking account...</loader>
          </div>
        </li>
        <li v-for="(account, index) in unlinkedAccounts" :key="index">
          <button class="account flex items-center">
            <div class="icon-container mr">
              <img :src="account.bankLogo" alt="bank_logo" class="icon no-mx" />
            </div>
            <div class="flex-1 mr-s">
              <h3 class="h3">{{ account.bankName }}</h3>
              <p class="grey">{{ account.accountNumber }}</p>
            </div>
            <button class="button dark-blue" @click="relinkAccount(index)">
              Reconnect
            </button>
          </button>
        </li>
      </ul>

      <p v-if="idle && unlinkedAccounts.length === 0" class="grey">
        You do not have any unlinked bank accounts.
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  fetchCancelledConnections,
  reconnectUserAccount,
} from '../utilities/api';
import { CHECKOUT } from '../utilities/sdkContext';

export default {
  states: ['fetching', 'idle', 'relinking'],
  computed: {
    ...mapGetters(['connectData', 'chargeData', 'sdkContext', 'banks']),
    sdkData() {
      return this.sdkContext == CHECKOUT ? this.chargeData : this.connectData;
    },
    userId() {
      return this.sdkData.userId;
    },
  },
  data() {
    return {
      unlinkedAccounts: [],
    };
  },
  methods: {
    async getUnlinkedAccounts() {
      this.currentState = 'fetching';

      const {
        success,
        body: unlinkedAccounts,
      } = await fetchCancelledConnections({ id: this.userId });
      if (success) {
        this.unlinkedAccounts = unlinkedAccounts
          .filter(({ slug }) => this.banks.find((b) => b.slug === slug))
          .map(({ accountName, accountNumber, slug, username }) => {
            const bank = this.banks.find((b) => b.slug === slug);

            return {
              accountName,
              accountNumber,
              username,
              bankName: bank.name,
              bankLogo: bank.logoUrl,
              slug,
            };
          });
      }

      this.currentState = 'idle';
    },
    async relinkAccount(index) {
      const { username, slug, accountNumber } = this.unlinkedAccounts[index];
      this.currentState = 'relinking';

      const { success, body: status } = await reconnectUserAccount({
        id: this.userId,
        username,
        slug,
        accountNumber,
        transfersType: 'checkout',
      });
      if (success) {
        if (status === 'successful') {
          this.unlinkedAccounts.splice(index, 1);
          this.$toasted.success('Account successfully re-linked.');
        } else
          this.$toasted.error('Sorry. We were unable to re-link that account');
      }

      this.currentState = 'idle';
    },
  },
  mounted() {
    this.$navigation.register({
      modal: 'Root',
      page: 'UnlinkedAccounts',
      push: () => {},
      pop: () => this.$root.$emit('continue', { page: 'accounts' }),
    });

    this.getUnlinkedAccounts();
  },
};
</script>

<style lang="scss" scoped>
button.button {
  width: initial !important;
}
</style>
