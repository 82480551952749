import AES from 'crypto-js/aes';
import { enc } from 'crypto-js/index';

function getEncryptionKey() {
  return localStorage.getItem('csek');
}

export async function encryptWithPublicKey(plainText, publicKey) {
  const aesCipherText = await AES.encrypt(plainText, publicKey);
  return aesCipherText.toString();
}

export async function encrypt(plainText) {
  const encryptionKey = getEncryptionKey();
  if (!encryptionKey) return null;

  return AES.encrypt(plainText, encryptionKey).toString();
}

export async function decrypt(cipherText) {
  const encryptionKey = getEncryptionKey();
  if (!encryptionKey) return null;

  try {
    return (await AES.decrypt(cipherText, encryptionKey)).toString(enc.Utf8);
  } catch(err) {
    return null;
  }
}