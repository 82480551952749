<template>
  <div class="flex direction-column flex-1">
    <template v-if="loading">
      <div class="modal__main flex-layout-center-center">
        <loader />
      </div>
    </template>

    <template v-else>
      <div class="modal__main sm:px">
        <header class="mb">
          <modal-title 
            :title="title" 
            :description="selectedBank.name"
            :img="{src: selectedBank.logoUrl}"
          />
        </header>
        <div>
          <div v-for="(entry, index) in form" :key="index" class="mb">
            <form-input :data="entry" :disabled="loading" />
          </div>
        </div>
      </div>
      <div class="modal__footer">
        <button
          class="button"
          @click="completePostSetup"
          :disabled="loading"
        >
          Continue
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FormInput from './shared/FormInput.vue';
import { completePostSetup } from '../utilities/api';
import { encryptWithPublicKey } from '../utilities/crypto';

export default {
  states: ['idle', 'loading'],
  components: { FormInput },
  computed: {
    ...mapGetters(['selectedBank', 'publicKey', 'chargeData', 'postSetupForm', 'userToken',]),
    title() {
      return this.selectedBank.postSetupForm[0].title;
    },
    form() {
      return this.selectedBank.postSetupForm;
    },
  },
  methods: {
    ...mapActions(['setUserToken']),
    async completePostSetup() {
      try {
        this.currentState = 'loading';
        let error;

        const formResponse = {};
        for (let index = 0; index < this.form.length; index++) {
          const entry = this.form[index];
          const value = this.getInputValue(entry.identifier);

          if (value.length === 0) {
            error = `Your ${entry.name} is required.`;
            break;
          }

          formResponse[entry.identifier] = await encryptWithPublicKey(
            value,
            this.publicKey
          );
        }

        if (error) {
          this.$toasted.error(error);
          return;
        }

        const { userId } = this.chargeData;
        const { slug } = this.selectedBank;

        const { success, errorMessage, body } = await completePostSetup({
          userId,
          slug,
          username: this.postSetupForm.username,
          ...formResponse,
        });
        if (!success) {
          this.$toasted.error(errorMessage);
        } else {
          this.setUserToken(body.token);
          this.$navigation.push('Root');
        }

        this.currentState = 'idle';
      } catch (err) {
        console.error(err);
      }
    },
  },
  mounted() {
    this.$navigation.register({
      modal: 'Root',
      page: 'PostSetup',
      push: () => this.$root.$emit('continue', { page: 'provide_credentials' }),
      pop: () => this.$root.$emit('continue', { page: 'pre_select' }),
    });
  },
};
</script>
