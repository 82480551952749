import Vue from 'vue';
import App from './App.vue';
import router from './router';
import Toasted from 'vue-toasted';
import RSST from './mixins/rsst';
import SetupEvents from './mixins/setupEvents';
import Navigation from './utilities/navigation';
import store from './store';
import VTooltip from 'v-tooltip';

import Loader from './components/shared/Loader.vue';
import ModalTitle from './components/shared/ModalTitle';

Vue.config.productionTip = false;

Vue.mixin(RSST);
Vue.mixin(SetupEvents);
Vue.mixin({
  methods: {
    getInput(identifier, index = 0) {
      return document.querySelectorAll(`input.${identifier}`)[index];
    },
    getInputValue(identifier, index = 0) {
      const input = this.getInput(identifier, index);
      if (!input) return '';

      return input.value;
    },
    resetForm() {
      document.querySelectorAll('input').forEach((input) => (input.value = ''));
    },
    closeConnectModal(accounts) {
      const data = JSON.stringify(
        accounts.map(
          ({
            defaultAccount,
            accountNumber,
            accountName,
            slug,
            balanceAmount,
            username,
          }) => ({
            accountNumber,
            accountName,
            slug,
            balance: balanceAmount,
            defaultAccount,
            username,
          })
        )
      );

      window.parent.postMessage(`sherlock:connectcomplete:${data}`, '*');
    }
  },
});

Vue.component('loader', Loader);
Vue.component('modal-title', ModalTitle);
Vue.use(Toasted, { position: 'bottom-center', duration: 5000 });
Vue.use(VTooltip);
Vue.prototype.$navigation = new Navigation();

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount('#app');
