import Vuex from 'vuex';
import Vue from 'vue';
import { getBanks } from './utilities/api';
import { CONNECT, CHECKOUT } from './utilities/sdkContext';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    banks: [],
    selectedBank: null,
    isTokenSet: false,
    chargeData: null,
    publicKey: null,
    accounts: [],
    selectedAccount: null,
    meta: {},
    postSetupForm: null,
    connectData: null,
    sdkContext: null,
    encryptedPassword: null,
    userToken: null
  },
  getters: {
    banks: (state) => state.banks,
    selectedBank: (state) => state.selectedBank,
    isTokenSet: (state) => state.isTokenSet,
    chargeData: (state) => state.chargeData,
    publicKey: (state) => state.publicKey,
    accounts: (state) => state.accounts,
    selectedAccount: (state) => state.selectedAccount,
    meta: (state) => state.meta,
    postSetupForm: (state) => state.postSetupForm,
    connectData: (state) => state.connectData,
    sdkContext: (state) => state.sdkContext,
    encryptedPassword: (state) => state.encryptedPassword,
    userToken: (state) => state.userToken,
  },
  mutations: {
    setBanks(state, banks) {
      state.banks = banks;
    },
    setSelectedBank(state, nubanCode) {
      state.selectedBank = state.banks.find(
        (bank) => bank.nubanCode === nubanCode
      );
    },
    setIsTokenSet(state) {
      state.isTokenSet = true;
    },
    setChargeData(state, chargeData) {
      state.sdkContext = CHECKOUT;
      state.chargeData = chargeData;
      this.dispatch('getBanks', CHECKOUT);
    },
    setMeta(state, meta) {
      state.publicKey = meta.publicKey;
      state.meta = meta;
    },
    clearAccounts(state) {
      state.accounts = [];
    },
    setSelectedAccount(state, index) {
      state.selectedAccount = state.accounts[index];
    },
    setPostSetupForm(state, postSetupForm) {
      state.postSetupForm = postSetupForm;
    },
    setConnectData(state, connectData) {
      state.sdkContext = CONNECT;
      state.connectData = connectData;
      this.dispatch('getBanks', CONNECT);
    },
    setEncryptedPassword(state, encryptedPassword) {
      state.encryptedPassword = encryptedPassword;
    },
    setUserToken(state, userToken) {
      state.userToken = userToken
    }
  },
  actions: {
    async getBanks({ commit }, sdkContext) {
      const response = await getBanks({ sdkContext });
      const usersPreferredBanks = response.body.slice(0, 3);
      const excludedBanks = this.state.meta.excludedBanks;
      const sortedBanks = response.body.slice(3).sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        } else if (a.name > b.name) {
          return 1;
        } else return 0;
      });
      const orderedBanks = usersPreferredBanks
        .concat(sortedBanks)
        .filter((bank) => !excludedBanks.includes(bank.slug));
      commit('setBanks', orderedBanks);
    },
    setSelectedBank({ commit }, nubanCode) {
      commit('setSelectedBank', nubanCode);
    },
    tokenIsSet({ commit }) {
      commit('setIsTokenSet');
    },
    setChargeData({ commit }, chargeData) {
      commit('setChargeData', chargeData);
    },
    setMeta({ commit }, meta) {
      commit('setMeta', meta);
    },
    clearAccounts({ commit }) {
      commit('clearAccounts');
    },
    setSelectedAccount({ commit }, index) {
      commit('setSelectedAccount', index);
    },
    setPostSetupForm({ commit }, postSetupForm) {
      commit('setPostSetupForm', postSetupForm);
    },
    setConnectData({ commit }, connectData) {
      commit('setConnectData', connectData);
    },
    setEncryptedPassword({ commit }, encryptedPassword) {
      commit('setEncryptedPassword', encryptedPassword);
    },
    setUserToken({ commit }, userToken) {
      commit('setUserToken', userToken);
    }
  },
});

export default store;
