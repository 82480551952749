<template>
  <div class="flex direction-column flex-1">
    <template v-if="loading">
      <div class="modal__main flex-layout-center-center">
        <loader />
      </div>
    </template>

    <template v-else-if="selectedBank">
      <div class="modal__main sm:px">
        <header class="mb">
          <modal-title
            :title="title"
            :description="selectedBank.name"
            :img="{ src: selectedBank.logoUrl }"
          />
        </header>
        <div>
          <div v-for="(entry, index) in form" :key="index" class="mb">
            <form-input
              :data="entry"
              @input="(val) => handleInputChange(val, entry.identifier)"
            />
          </div>
        </div>
      </div>
      <div class="modal__footer">
        <button
          class="button button--large"
          @click="completePreSetup"
          :disabled="loading"
        >
          {{ title }}
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FormInput from './shared/FormInput.vue';
import { completePreSetup } from '../utilities/api';
import { encryptWithPublicKey } from '../utilities/crypto';
import { CONNECT, CHECKOUT } from '../utilities/sdkContext';

export default {
  states: ['idle', 'loading'],
  components: { FormInput },
  computed: {
    ...mapGetters([
      'selectedBank',
      'publicKey',
      'chargeData',
      'postSetupForm',
      'sdkContext',
      'connectData',
    ]),
    form() {
      return this.selectedBank.preSetupForm;
    },
    title() {
      return this.form[0].title;
    },
    sdkData() {
      return this.sdkContext == CHECKOUT ? this.chargeData : this.connectData;
    },
  },
  data() {
    return {
      inputValues: {},
      CONNECT,
      CHECKOUT,
    };
  },
  methods: {
    ...mapActions(['setPostSetupForm']),
    handleInputChange(val, key) {
      this.inputValues[key] = val;
    },
    async completePreSetup() {
      try {
        this.currentState = 'loading';
        let error;

        const formResponse = {};

        for (let index = 0; index < this.form.length; index++) {
          const entry = this.form[index];
          const value = this.inputValues[entry.identifier];

          if (value.length === 0) {
            error = `Your ${entry.name} is required.`;
            break;
          }

          formResponse[entry.identifier] = await encryptWithPublicKey(
            value,
            this.publicKey
          );
        }

        if (error) {
          this.$toasted.error(error);
          this.currentState = 'idle';
          return;
        }

        const { userId } = this.sdkData;
        const { slug } = this.selectedBank;

        const { success, errorMessage, body } = await completePreSetup({
          userId,
          slug,
          ...formResponse,
        });
        if (!success) {
          this.$toasted.error(errorMessage);
        } else {
          this.setPostSetupForm(body);
          this.$navigation.push('Root');
        }

        this.currentState = 'idle';
      } catch (err) {
        console.error(err);
        this.currentState = 'idle';
      }
    },
  },
  mounted() {
    this.$navigation.register({
      modal: 'Root',
      page: 'PreSetup',
      push: () => {
        if (
          this.postSetupForm &&
          this.postSetupForm.requiredFields.length > 0
        ) {
          this.$root.$emit('continue', { page: 'post_setup' });
        } else this.$root.$emit('continue', { page: 'provide_credentials' });
      },
      pop: () => this.$root.$emit('continue', { page: 'bank_select' }),
    });
  },
};
</script>
