import axios from 'axios';

async function _makeRequest({
  query,
  variables,
  headers,
  getData,
  bodyCanBeNull,
}) {
  try {
    bodyCanBeNull = bodyCanBeNull === true ? bodyCanBeNull : false;
    headers = headers || {};
    const response = await axios.post('', { query, variables }, { headers });

    const result = {
      success: false,
      errorCode: null,
      errorMessage: null,
      body: null,
    };

    const body = getData(response.data?.data);
    if (response.data.errors || (body === null && !bodyCanBeNull)) {
      result.errorCode = response.data.errors[0].extensions.code;

      if (result.errorCode === 'BANK_ERROR' || result.errorCode === 'SHERLOCK_INTERNAL_ERROR' || result.errorCode === 'RESTRICTION_ERROR') 
        result.errorMessage = response.data.errors[0].message;
      else result.errorMessage = 'Something went wrong. Please try again.';
    }

    if (body) {
      result.success = true;
      result.body = body;
    }

    return result;
  } catch (err) {
    console.log(err);
    return {
      success: false,
      errorCode: 'SERVICE_UNAVAILABLE',
      errorMessage: "We're not sure what went wrong here. Please try again.",
      body: null,
    };
  }
}

export async function getBanks({ sdkContext }) {
  const query = `
    query ($transfersType: TransfersType) {
      fetchBankDetails(transfersType: $transfersType) {
        slug
        type
        name
        colour
        logoUrl
        nubanCode
        loginForm {
          name
          type
          identifier
        }
        transferForm {
          name
          type
          identifier
        }
        preSetupForm{
          title
          name
          identifier
          type
        }
        postSetupForm{
          title
          name
          identifier
          type
        }
        finalizeTransferForm{
          name
          identifier
          type
        }
      }
    }
  `;

  return _makeRequest({
    query,
    variables: { transfersType: sdkContext },
    getData: (data) => data.fetchBankDetails,
  });
}

export async function login({
  slug,
  userId,
  username,
  password,
  passwordForKeeps,
  transfersType,
  reconnectDisconnectedAccounts = true,
}) {
  const query = `
    mutation ($slug: BankType!, $userId: ID!, $username: String!, $password: String!, $passwordForKeeps: String!, $transfersType: TransfersType, $reconnectDisconnectedAccounts: Boolean) {
      connectUserBank(reconnectDisconnectedAccounts: $reconnectDisconnectedAccounts,input: {
        slug: $slug,
        userId: $userId,
        username: $username,
        password: $password,
        passwordForKeeps: $passwordForKeeps,
        transfersType: $transfersType
      }) {
        id
        bankLoginConnection {
          accountName
          accountNumber
          balance
        }
        isVerified
      }
    }
  `;

  return _makeRequest({
    query,
    variables: {
      slug,
      userId,
      username,
      password,
      passwordForKeeps,
      transfersType,
      reconnectDisconnectedAccounts,
    },
    getData: (data) => data.connectUserBank,
    bodyCanBeNull: true,
  });
}

export async function completeKudaLogin({
  userId,
  username,
  password,
  passwordForKeeps,
  transfersType,
  otp,
}) {
  const query = `
    mutation ($otp: String!, $userId: ID!, $username: String!, $password: String!, $passwordForKeeps: String!, $transfersType: TransfersType) {
      completeKudaLoginWithOtp(input: {
        userId: $userId,
        username: $username,
        password: $password,
        passwordForKeeps: $passwordForKeeps,
        transfersType: $transfersType,
        otp: $otp
      }) {
        id
        bankLoginConnection {
          accountNumber
          balance
        }
        isVerified
      }
    }
  `;

  return _makeRequest({
    query,
    variables: {
      userId,
      username,
      password,
      passwordForKeeps,
      transfersType,
      otp,
    },
    getData: (data) => data.completeKudaLoginWithOtp,
  });
}

export async function logout({
  userId,
  username,
  slug,
  transfersType,
  accountNumber,
}) {
  const query = `
    mutation($id: ID!, $username: String!, $slug: BankType!, $transfersType: TransfersType, $accountNumber: String) {
      cancelUserConnection(id: $id, username: $username, slug: $slug, transfersType: $transfersType, accountNumber: $accountNumber) 
    }
  `;

  return _makeRequest({
    query,
    variables: { id: userId, username, slug, transfersType, accountNumber },
    getData: (data) => data.cancelUserConnection === 'successful',
  });
}

export async function fetchPassword(userId) {
  const query = `
    query ($userId: ID!) {
      fetchPassword(id: $userId) {
        username
        password
        slug
      }
    }
  `;

  return _makeRequest({
    query,
    variables: { userId },
    getData: (data) => (data ? data.fetchPassword : []),
  });
}

export async function initiatePayment({
  accountNumber,
  userId,
  username,
  name,
  slug,
  transactionReference: reference,
  destinationAccountNumber,
  destinationBankCode,
  payoutType,
  amount,
  signature,
}) {
  const query = `
    mutation ($accountNumber: String!, $userId: ID!, $slug: BankType!,
      $reference: String!, $destinationAccountNumber: String,
      $destinationBankCode: String, $amount: Float!, $payoutType: PayoutType!, $name: String, $username: String!) {
      initiateTransferRequest(input: {
        username: $username,
        sourceAccountNumber: $accountNumber,
        sourceUserId: $userId,
        slug: $slug,
        beneficiary: {
          name: $name,
          reference: $reference,
          destinationAccountNumber: $destinationAccountNumber,
          destinationBankCode: $destinationBankCode,
          amount: $amount,
          payoutType: $payoutType,
        }
      }) {
        id
        status
        requiredFields
      }
    }
  `;

  return _makeRequest({
    query,
    variables: {
      username,
      accountNumber,
      userId,
      name,
      slug,
      reference,
      destinationAccountNumber,
      destinationBankCode,
      amount,
      payoutType,
    },
    headers: {
      'X-Transfers-Request-Signature': signature,
    },
    getData: (data) => (data ? data.initiateTransferRequest[0] : null),
  });
}

export async function initiateBusinessPayment({
  accountNumber,
  userId,
  username,
  password,
  name,
  slug,
  transactionReference: reference,
  destinationAccountNumber,
  destinationBankCode,
  payoutType,
  amount,
  signature,
}) {
  const query = `
    mutation ($accountNumber: String!, $userId: ID!, $slug: BankType!,
      $reference: String!, $destinationAccountNumber: String,
      $destinationBankCode: String, $amount: Float!, $payoutType: PayoutType!, $name: String, $username: String!, $password: String!) {
      initiateBusinessBankTransferRequest(input: {
        username: $username,
        password: $password,
        sourceAccountNumber: $accountNumber,
        sourceUserId: $userId,
        slug: $slug,
        beneficiary: {
          name: $name,
          reference: $reference,
          destinationAccountNumber: $destinationAccountNumber,
          destinationBankCode: $destinationBankCode,
          amount: $amount,
          payoutType: $payoutType,
        }
      }) {
        id
        status
        requiredFields
      }
    }
  `;

  return _makeRequest({
    query,
    variables: {
      username,
      password,
      accountNumber,
      userId,
      name,
      slug,
      reference,
      destinationAccountNumber,
      destinationBankCode,
      amount,
      payoutType,
    },
    headers: {
      'X-Transfers-Request-Signature': signature,
    },
    getData: (data) =>
      data ? data.initiateBusinessBankTransferRequest[0] : null,
  });
}

export async function approvePayment({
  paymentId,
  password,
  pin,
  otp,
  token,
  secretAnswer,
}) {
  const query = `
    mutation ($paymentId: ID!, $password: String!, $token: String, $pin: String, $otp: String, $secretAnswer: String) {
      approveRequest(input: { id: $paymentId, password: $password, token: $token, secretAnswer: $secretAnswer, pin: $pin, otp: $otp }) {
        id
        status
        requiredFields
      }
    }
  `;

  return _makeRequest({
    query,
    variables: { paymentId, password, pin, otp, token, secretAnswer },
    getData: (data) =>
      data
        ? data.approveRequest.length > 0
          ? data.approveRequest[0]
          : {}
        : null,
  });
}

export async function completePreSetup({ userId, slug, cardNumber, cardPin, phoneNumber }) {
  const query = `
    mutation($slug: BankType!, $sourceUserId: String!, $cardNumber: String, $cardPin: String, $phoneNumber: String) {
      completePreSetup(input: { slug: $slug, sourceUserId: $sourceUserId, cardNumber: $cardNumber, cardPin: $cardPin, phoneNumber: $phoneNumber }) {
        username
        requiredFields
      }
    }
  `;

  return _makeRequest({
    query,
    variables: { sourceUserId: userId, slug, cardNumber, cardPin, phoneNumber },
    getData: (data) => (data ? data.completePreSetup : null),
  });
}

export async function completePostSetup({ userId, slug, username, otp }) {
  const query = `
    mutation($slug: BankType!, $sourceUserId: String!, $username: String!, $otp: String) {
      completePostSetup(input: { slug: $slug, sourceUserId: $sourceUserId, username: $username, otp: $otp }) {
        username
        token
      }
    }
  `;

  return _makeRequest({
    query,
    variables: { sourceUserId: userId, slug, username, otp },
    getData: (data) => (data ? data.completePostSetup : null),
  });
}

export async function finalizePayment({ paymentId: id, otp }) {
  const query = `
    mutation($id: ID!, $otp: String) {
      finalizeTransferRequest(input: { id: $id, otp: $otp }) {
        id
        status
      }
    }
  `;

  return _makeRequest({
    query,
    variables: { id, otp },
    getData: (data) => (data ? data.finalizeTransferRequest : null),
  });
}

export async function fetchConnections(userId) {
  const query = `
    query ($id: String!) {
      fetchAllConnections(id: $id) {
        id
        username
        slug
        defaultAccount
        accountNumber
        accountName
        balance
      }
    }
  `;

  return _makeRequest({
    query,
    variables: { id: userId },
    getData: (data) => (data ? data.fetchAllConnections : []),
  });
}

export async function setDefaultAccount({
  userId,
  username,
  accountNumber,
  slug,
}) {
  const query = `
    mutation ($id: ID!, $username: String!, $accountNumber: String!, $slug: BankType!) {
      setDefaultAccount(id: $id, username: $username, accountNumber: $accountNumber, slug: $slug)
    }
  `;

  return _makeRequest({
    query,
    variables: { id: userId, username, accountNumber, slug },
    getData: (data) => (data ? data.setDefaultAccount === 'successful' : false),
  });
}

export async function startBankAccountVerification({
  username,
  slug,
  verificationPhoneNumber,
}) {
  const query = `
    mutation($username: String!, $slug: BankType!, $verificationPhoneNumber: String) {
      startBankAccountVerification(username: $username, slug: $slug, verificationPhoneNumber: $verificationPhoneNumber)
    }
  `;

  return _makeRequest({
    query,
    variables: { username, slug, verificationPhoneNumber },
    getData: (data) => (data ? data.startBankAccountVerification : null),
  });
}

export async function finalizeBankAccountVerification({ username, slug, otp }) {
  const query = `
    mutation($username: String!, $slug: BankType!, $otp: String!) {
      finaliseBankAccountVerification(username: $username, slug: $slug, otp: $otp)
    }
  `;

  return _makeRequest({
    query,
    variables: { username, slug, otp },
    getData: (data) => (data ? data.finaliseBankAccountVerification : null),
  });
}

export async function fetchCancelledConnections({ id }) {
  const query = `
    query($id: String!, $transfersType: TransfersType) {
      fetchCancelledConnections(id: $id,transfersType: $transfersType) {
        id
        username
        slug
        accountNumber
        accountName
        bankNubanCode
        isPrimary
      }
    }
  `;

  return _makeRequest({
    query,
    variables: { id, transfersType: 'checkout' },
    getData: (data) => (data ? data.fetchCancelledConnections : null),
  });
}

export async function reconnectUserAccount({
  id,
  username,
  slug,
  accountNumber,
  transfersType,
}) {
  const query = `
    mutation($id: ID!, $username: String!, $slug: BankType!, $transfersType: TransfersType, $accountNumber: String!) {
      reconnectUserAccount(id: $id, username: $username, slug: $slug, transfersType: $transfersType, accountNumber: $accountNumber)
    }
  `;

  return _makeRequest({
    query,
    variables: { id, username, slug, accountNumber, transfersType },
    getData: (data) => (data ? data.reconnectUserAccount : 'unsuccessful'),
  });
}

export async function getPaymentStatus(id) {
  try {
    const response = await window.restAPI.get(`/status?id=${id}`);
    return {
      code: response.status,
      ...response.data.Payment,
      errors: response.data.errors,
    };
  } catch (err) {
    return { code: err.response.status };
  }
}
