/* eslint-disable no-plusplus, no-unused-vars */

export default class Navigation {
  navigationStack = {};

  contexts = {};

  getCurrentPage(modal) {
    const stack = this.getStack(modal);
    if (stack && stack.length > 0) {
      return stack[stack.length - 1];
    }

    return null;
  }

  getStack(modal) {
    return this.navigationStack[modal];
  }

  setup({ modal, context }) {
    if (context.canGoBack !== false) {
      throw new Error(
        'Navigation requires a canGoBack boolean data property on your Root modal'
      );
    }

    this.contexts[modal] = context;
    if (!this.navigationStack[modal]) this.navigationStack[modal] = [];
  }

  register({ modal, page, push, pop }) {
    if (!this.navigationStack[modal])
      throw new Error(`${modal} has not been setup for navigation.`);

    const existingEntry = this.navigationStack[modal].findIndex(
      (p) => p.page === page
    );
    if (existingEntry === -1) {
      this.navigationStack[modal].push({
        page,
        push,
        pop,
      });

      const stack = this.getStack(modal);
      if (stack && stack.length > 1) this.contexts[modal].canGoBack = true;
    } else {
      const entry = this.navigationStack[modal][existingEntry];
      entry.push = push;
      entry.pop = pop;
    }
  }

  push(modal) {
    const currentPage = this.getCurrentPage(modal);
    if (currentPage) currentPage.push();
  }

  pop(modal) {
    const currentPage = this.getCurrentPage(modal);
    if (currentPage) {
      currentPage.pop();

      const stack = this.getStack(modal);
      if (stack && stack.length > 1) --stack.length;
      this.contexts[modal].canGoBack = stack.length > 1;
    }
  }

  clear(modal) {
    this.navigationStack[modal] = [];
    this.contexts[modal].canGoBack = false;
  }
}
