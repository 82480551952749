var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('label',{staticClass:"small grey",attrs:{"for":_vm.data.identifier}},[_vm._v(_vm._s(_vm.data.name))]),_c('div',{class:{ 'input-container': _vm.data.type === 'password' }},[((_vm.data.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],staticClass:"input",class:_vm.data.identifier,attrs:{"autocapitalize":_vm.data.name.toLowerCase().includes('email address') ? 'none' : 'on',"id":_vm.data.identifier,"inputmode":_vm.data.name.toLowerCase().includes('transaction pin') ||
        _vm.inputmode === 'numeric'
          ? 'numeric'
          : 'text',"disabled":_vm.disabled,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.value)?_vm._i(_vm.value,null)>-1:(_vm.value)},on:{"change":[function($event){var $$a=_vm.value,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.value=$$a.concat([$$v]))}else{$$i>-1&&(_vm.value=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.value=$$c}},_vm.handleChange]}}):((_vm.data.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],staticClass:"input",class:_vm.data.identifier,attrs:{"autocapitalize":_vm.data.name.toLowerCase().includes('email address') ? 'none' : 'on',"id":_vm.data.identifier,"inputmode":_vm.data.name.toLowerCase().includes('transaction pin') ||
        _vm.inputmode === 'numeric'
          ? 'numeric'
          : 'text',"disabled":_vm.disabled,"type":"radio"},domProps:{"checked":_vm._q(_vm.value,null)},on:{"change":[function($event){_vm.value=null},_vm.handleChange]}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],staticClass:"input",class:_vm.data.identifier,attrs:{"autocapitalize":_vm.data.name.toLowerCase().includes('email address') ? 'none' : 'on',"id":_vm.data.identifier,"inputmode":_vm.data.name.toLowerCase().includes('transaction pin') ||
        _vm.inputmode === 'numeric'
          ? 'numeric'
          : 'text',"disabled":_vm.disabled,"type":_vm.data.type},domProps:{"value":(_vm.value)},on:{"change":_vm.handleChange,"input":function($event){if($event.target.composing){ return; }_vm.value=$event.target.value}}}),(_vm.data.type === 'password' && _vm.showPasswordToggle)?_c('password-toggle',{attrs:{"inputId":_vm.data.identifier}}):_vm._e()],1),(_vm.tooltip.length > 0)?_c('p',{staticClass:"tooltip extra-small flex items-center"},[_c('svg',{staticClass:"mr-s",attrs:{"width":"14","height":"14","viewBox":"0 0 14 14","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M7.0001 0.600098C10.5361 0.600098 13.4001 3.4641 13.4001 7.0001C13.4001 10.5361 10.5361 13.4001 7.0001 13.4001C3.4641 13.4001 0.600098 10.5361 0.600098 7.0001C0.600098 3.4641 3.4641 0.600098 7.0001 0.600098ZM7.8001 3.8001C7.8001 3.3601 7.4401 3.0001 7.0001 3.0001C6.5601 3.0001 6.2001 3.3601 6.2001 3.8001C6.2001 4.2401 6.5601 4.6001 7.0001 4.6001C7.4401 4.6001 7.8001 4.2401 7.8001 3.8001ZM7.8001 11.0001V6.2001H6.2001V11.0001H7.8001Z","fill":"#2F3134"}})]),_c('span',{staticClass:"flex-1",domProps:{"innerHTML":_vm._s(_vm.tooltip)}})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }