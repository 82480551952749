<template>
  <div class="flex direction-column flex-1">
    <div class="h-full" v-show="loading">
      <div class="modal__main flex-layout-center-center h-full">
        <loader>
          <span v-if="this.selectedBank.slug === 'kuda' && !isRequestingKudaOtp"
            >Verifying Account</span
          >
          <span v-else-if="isRequestingKudaOtp && isResendingOtp"
            >Resending</span
          >
          <span v-else>Linking Account</span>
        </loader>
      </div>
    </div>

    <div class="modal__main sm:px" v-show="idle">
      <header class="mb">
        <modal-title
          :description="{ text: selectedBank.name, class: 'h2' }"
          :img="{ src: selectedBank.logoUrl }"
          :caption="infoMsg"
        />
      </header>
      <div v-show="!isRequestingKudaOtp">
        <div
          class="mb"
          v-for="(entry, index) in selectedBank.loginForm"
          :key="index"
        >
          <form-input :data="entry" :disabled="loading" :slug="selectedBank.slug" />
        </div>
      </div>

      <div v-show="isRequestingKudaOtp">
        <form-input
          v-if="isRequestingKudaOtp"
          :data="otpFormInputData"
          :disabled="loading"
          inputmode="numeric"
        />
        <div class="text-center mt-s mb-l" v-if="showResendOtp">
          <button class="link-button" @click="resendOtp">Resend OTP</button>
        </div>
      </div>

      <div class="flex justify-center mt">
        <button
          class="button button--footer"
          @click="submitCredentials"
          :disabled="loading"
          data-analytics-event="Clicked Login Button"
          data-analytics-page="credential"
        >
          Log in
        </button>
      </div>

      <div class="text-center mt-s mb-l" v-if="meta.siteHelpUrl && resendOtpClickCount >= 2">
          <a :href="meta.siteHelpUrl" target="_blank" rel="noopener noreferrer">Need help? Contact us</a>
      </div>
    </div>

    <div v-show="linked" class="modal__main sm:px">
      <div class="mt-xl flex-layout-center-center direction-column text-center">
        <img
          src="./../assets/account_linked.svg"
          alt="success_ellipse"
          height="100"
        />
        <h2 class="h2 text-center mt mb-s">Account Linked</h2>
        <p>
          Your {{ selectedBank.name }} account was successfully linked. You can
          now make transfers from this account.
        </p>
      </div>
    </div>

    <div v-if="linked" class="modal__footer flex justify-center mb">
      <button class="button" @click="$navigation.push('Root')">
        Continue
      </button>
    </div>

    <div v-show="verify || verifying">
      <div class="modal__main direction-column sm:px">
        <header class="mb">
          <h2 class="h2">Verify your identity</h2>
          <p class="mt-s">
            To proceed, we need to verify your identity. Please provide the OTP
            that was sent to the phone number tied to your bank account's BVN.
          </p>
          <br />
        </header>
        <div class="mt">
          <form-input :data="otpFormInputData" :disabled="verifying" />
        </div>
        <div class="modal__footer flex justify-center mb">
          <button
            class="button"
            @click="finalizeBankAccountVerification"
            :disabled="verifying"
          >
            Verify
          </button>
        </div>
        <small class="block text-center mt">
          If you don't have access to your BVN phone number, please contact us
          at
          <a href="mailto:support@sendcashpay.com">support@sendcashpay.com</a>
        </small>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  login,
  completeKudaLogin,
  startBankAccountVerification,
  finalizeBankAccountVerification,
} from '../utilities/api';
import { encrypt, encryptWithPublicKey } from '../utilities/crypto';
import { CHECKOUT, CONNECT } from '../utilities/sdkContext';
import FormInput from './shared/FormInput.vue';

export default {
  components: { FormInput },
  states: ['idle', 'loading', 'linked', 'verify', 'verifying'],
  computed: {
    ...mapGetters([
      'meta',
      'selectedBank',
      'chargeData',
      'publicKey',
      'postSetupForm',
      'connectData',
      'sdkContext',
      'userToken',
    ]),
    sdkData() {
      return this.sdkContext === CHECKOUT ? this.chargeData : this.connectData;
    },
    infoMsg() {
      switch (true) {
        case this.isRequestingKudaOtp:
          return 'Please provide the Kuda OTP code sent to your phone number.';

        case this.slugIsChippercash:
          return "Please click the 'Log in' button to proceed with your payment.";

          case this.slugIsAbeg:
            return "Please note that you are to provide the Pocket app OTP sent to your phone number to log in.";

        default:
          return 'Please note that you are to enter your bank mobile app login credentials.';
      }
    },
  },
  data() {
    return {
      isRequestingKudaOtp: false,
      otpFormInputData: {
        name: 'OTP',
        identifier: 'otp',
        type: 'password',
      },
      username: '',
      isResendingOtp: false,
      showResendOtp: false,
      slugIsChippercash: false,
      slugIsAbeg: false,
      resendOtpClickCount: 0,
    };
  },
  methods: {
    ...mapActions(['encryptPassword', 'setEncryptedPassword']),
    async submitCredentials() {
      try {
        this.currentState = 'loading';

        let username = this.getInputValue('login');
        const password = this.getInputValue('password');
        const otp = this.getInputValue('otp');
        const { slug, type } = this.selectedBank;

        if (slug === 'kuda' && password.length > 0 && password.length < 8) {
          this.$toasted.info(
            'Looks like you provided your Kuda PIN instead of your login password.'
          );
          this.currentState = 'idle';
          return;
        }

        const publicKeyEncryptedPassword = await encryptWithPublicKey(
          password,
          this.publicKey
        );
        const clientSideKeyEncryptedPassword = await encrypt(password);
        if (type === 'business') {
          this.setEncryptedPassword(publicKeyEncryptedPassword);
        }
        const loginPayload = {
          username,
          password: publicKeyEncryptedPassword,
          slug,
          userId: this.sdkData.userId,
          passwordForKeeps: clientSideKeyEncryptedPassword,
          transfersType: this.sdkContext,
          otp,
        };

        const { success, errorMessage, body } = await (this.isRequestingKudaOtp
          ? completeKudaLogin(loginPayload)
          : login(loginPayload));

        const requestKudaOTP = () => {
          this.isRequestingKudaOtp = true;
          this.$toasted.info(
            'Please provide the Kuda OTP code sent to your phone number.'
          );
          this.currentState = 'idle';
          setTimeout(() => {
            this.showResendOtp = true;
          }, 5000);
        };

        if (!success) {
          // Kuda bank could return a proper body or null when successful
          if (slug === 'kuda' && errorMessage === null) {
            requestKudaOTP();
          } else {
            this.$toasted.error(errorMessage);
            this.currentState = 'idle';
          }
        } else {
          if (this.sdkContext === CONNECT) {
            this.currentState = 'linked';
            return;
          }

          if (!body.isVerified) {
            const { phone: verificationPhoneNumber } = this.sdkData;
            const {
              success,
              errorMessage,
            } = await startBankAccountVerification({
              username,
              slug,
              verificationPhoneNumber,
            });

            if (success) {
              this.username = username;
              this.currentState = 'verify';
            } else {
              this.$toasted.error(errorMessage);
              this.currentState = 'idle';
            }
          } else this.currentState = 'linked';
        }
      } catch (err) {
        if (this.verifying) this.currentState = 'verify';
        else this.currentState = 'idle';
      }
    },
    async finalizeBankAccountVerification() {
      const otp = this.getInputValue('otp');
      const { slug } = this.selectedBank;

      this.currentState = 'verifying';
      const { body: verified } = await finalizeBankAccountVerification({
        otp,
        username: this.username,
        slug,
      });
      if (verified) this.currentState = 'linked';
      else {
        this.$toasted.error('Something went wrong. Please try again');
        this.currentState = 'verify';
      }
    },
    async resendOtp() {
      try {
        this.resendOtpClickCount += 1;        
        this.isResendingOtp = true;
        this.currentState = 'loading';
        this.showResendOtp = false;

        let username = this.getInputValue('login');
        const password = this.getInputValue('password');
        const { slug, type } = this.selectedBank;

        const publicKeyEncryptedPassword = await encryptWithPublicKey(
          password,
          this.publicKey
        );
        const clientSideKeyEncryptedPassword = await encrypt(password);

        if (type === 'business') {
          this.setEncryptedPassword(publicKeyEncryptedPassword);
        }
        const loginPayload = {
          username,
          password: publicKeyEncryptedPassword,
          slug,
          userId: this.sdkData.userId,
          passwordForKeeps: clientSideKeyEncryptedPassword,
          transfersType: this.sdkContext,
        };

        const { success, errorMessage } = await login(loginPayload);

        if (!success) {
          this.$toasted.error(errorMessage);
        } else {
          this.$toasted.info("We've resent the code");
        }
        this.currentState = 'idle';
      } catch (err) {
        this.currentState = 'idle';
      } finally {
        this.isResendingOtp = false;
        setTimeout(() => {
          this.showResendOtp = true;
        }, 5000);
      }
    },
  },
  mounted() {
    this.$navigation.register({
      modal: 'Root',
      page: 'ProvideCredentials',
      push: () => {
        this.$navigation.clear('Root');
        this.$root.$emit('continue', { page: 'accounts' });
      },
      pop: () => this.$root.$emit('continue', { page: 'bank_select' }),
    });

    if (this.selectedBank.slug === 'fbn') {
      const usernameInput = this.getInput('login');
      usernameInput.value = this.postSetupForm.username;
      usernameInput.setAttribute('disabled', 'true');
    }

    if (this.selectedBank.slug === 'chippercash') {
      this.slugIsChippercash = true;
      const usernameInput = this.getInput('login');
      usernameInput.value = this.postSetupForm.username;
      usernameInput.setAttribute('disabled', 'true');

      const tokenInput = this.getInput('password');
      tokenInput.value = this.userToken;
      tokenInput.setAttribute('disabled', 'true');
    }

    if (this.selectedBank.slug === 'abeg') {
      this.slugIsAbeg = true;
    }
  },
};
</script>

<style lang="scss" scoped>
header img {
  max-width: 80px;
  object-fit: cover;
}
</style>
