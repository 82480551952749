<template>
  <div class="flex direction-column flex-1 scroll-y">
    <template v-if="initiating || debiting || finalizing">
      <div class="modal__main flex-layout-center-center">
        <loader>
          <span v-if="initiating">Initiating</span>
          <span v-if="debiting">Authorising payment</span>
        </loader>
      </div>
    </template>

    <template v-if="idle || approving">
      <div class="modal__main sm:px">
        <header class="mb">
          <modal-title title="Authorize Payment" />
        </header>

        <ul class="list list--basic text-lg mb">
          <li class="no-py no-px">
            <p class="small grey">Bank account</p>
            <dl class="summary-list">
              <dt class="text-md">
                <span v-if="selectedAccount.accountNumber">
                  {{ selectedAccount.accountNumber }}
                </span>
              </dt>
              <dd>
                {{ selectedAccount.bankName }}
              </dd>
            </dl>
          </li>
          <li class="no-py no-px">
            <dl class="summary-list" v-if="this.selectedAccount.balance">
              <dt class="grey">Account Balance</dt>
              <dd>{{ this.selectedAccount.balance }}</dd>
            </dl>
          <li class="mt">
            <dl class="summary-list">
              <dt class="grey">Total Amount to pay</dt>
              <dd class="text-lg">{{ amount }}</dd>
            </dl>
          </li>
        </ul>

        <section>
          <div>
            <p class="small mb-xs" v-if="transferForm.length > 0">
              Enter the authorisation details for your {{selectedAccount.bankName ||''}} account.
            </p>

            <div v-for="(entry, index) in transferForm" :key="index" class="mb">
              <form-input
                :data="entry"
                :disabled="debiting"
                :tooltip="getTooltip(entry.identifier)"
                inputmode="text"
              />
              <small
                v-if="['secretAnswer'].includes(entry.identifier)"
                class="input-error callout extra-small mt-s"
              >
                {{ entry.name }} is case sensitive.
              </small>
            </div>

            <div v-if="selectedAccount.slug === 'chippercash'" class="mb">
              <small class="grey">
                You are about to authorise
                <strong>{{ meta.siteName }}</strong> to debit
                <strong>{{ amount }}</strong> from your
                <strong>{{ selectedAccount.bankName }}</strong> account. Click the
                button below to continue.
              </small>
            </div>
          </div>

          <div class="modal__footer flex direction-column justify-center mb">
            <button
              class="button button--large"
              @click="approveDebit"
              :disabled="debiting"
              data-analytics-event="Authorised Debit"
              data-analytics-page="pay"
            >
              Pay
            </button>
            <div class="text-center mt">
              <small class="grey small">
                By clicking "Pay", you authorise
                <strong>{{ meta.siteName }}</strong> to debit
                <strong>{{ amount }}</strong> from your
                <strong>{{ selectedAccount.bankName }}</strong> account in
                accordance with the
                <a
                  :href="meta.privacyPolicyUrl || 'https://sendcashpay.com/privacy'"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {{ meta.privacyPolicyUrl ? meta.siteName : "Sendcash Pay" }} End
                  User Privacy Policy
                </a>
              </small>
            </div>
          </div>
        </section>
      </div>
    </template>

    <template v-else-if="paid">
      <div
        class="modal__main flex-layout-center-center direction-column text-center sm:px"
      >
        <img
          src="../assets/check.gif"
          alt="success_ellipse"
          height="130"
          width="130"
        />
        <h2 class="h2 text-center mt">Payment Initiated</h2>
        <p>
          Your payment was successfully initiated. Click the button below to go
          back to {{ meta.siteName }}.
        </p>
      </div>
      <div class="modal__footer flex justify-center mb">
        <button class="button button--large" @click="finishPayment">
          Redirecting to {{ meta.siteName }} in
          {{ paymentInitiatedRedirectSecs }} seconds
        </button>
      </div>
    </template>

    <template v-else-if="finalize">
      <div class="modal__main sm:px">
        <header class="mb">
          <modal-title title="Finalize payment" />
        </header>

        <div
          v-for="(entry, index) in finalizeTransferForm"
          :key="index"
          class="mb"
        >
          <form-input :data="entry" :disabled="finalizing" />
        </div>
      </div>

      <div class="modal__footer mb">
        <button
          class="button button--large"
          @click="finalizePayment"
          :disabled="finalizing"
        >
          Finalize debit of {{ amount }}
        </button>
      </div>
    </template>

    <template v-else-if="paymentFailed">
      <div class="modal__main sm:px flex flex-1 direction-column text-center">
        <h2 class="h2 mt mb-s" v-html="paymentError"></h2>
        <p>
          Redirecting you to <strong>{{ meta.siteName }}</strong> in
          <strong>{{ redirectSecs }} secs</strong>
        </p>
      </div>
      <div class="modal__footer flex justify-center mb">
        <button class="button button--large" @click="finishPayment">
          Back to {{ meta.siteName }}
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  approvePayment,
  initiatePayment,
  finalizePayment,
  initiateBusinessPayment,
  getPaymentStatus,
} from '../utilities/api';
import { encryptWithPublicKey } from '../utilities/crypto';
import formatMoney from '../utilities/formatMoney';
import FormInput from './shared/FormInput.vue';
import ModalTitle from './shared/ModalTitle.vue';

export default {
  components: { FormInput, ModalTitle },
  states: [
    'idle',
    'initiating',
    'approving',
    'debiting',
    'paid',
    'finalize',
    'finalizing',
    'paymentFailed',
  ],
  computed: {
    ...mapGetters([
      'selectedAccount',
      'chargeData',
      'meta',
      'banks',
      'publicKey',
      'encryptedPassword',
    ]),
    amount() {
      return formatMoney(this.chargeData.amount);
    },
    bank() {
      return this.selectedAccount
        ? this.banks.find((bank) => bank.slug === this.selectedAccount.slug)
        : null;
    },
    transferForm() {
      return this.bank
        ? this.bank.transferForm.filter((f) =>
            this.requiredFields.includes(f.identifier)
          )
        : [];
    },
    finalizeTransferForm() {
      return this.bank
        ? this.bank.finalizeTransferForm.filter((f) =>
            this.requiredFields.includes(f.identifier)
          )
        : [];
    },
  },
  data() {
    return {
      paymentId: '',
      requiredFields: [],
      retries: 0,
      paymentError: '',
      redirectSecs: 7,
      redirectTimer: null,
      paymentInitiatedRedirectSecs: 5,
      paymentInitiatedRedirectTimer: null,
      statusRefreshTimer: null,
      helpfulInfo: {
        kuda: {
          pin:
            "This is the pin you use to make transactions on Kuda's website and mobile app",
        },
        abeg: {
          pin:
            "This is the pin you use to make transactions on Pocket's mobile app",
        },
        zenith: {
          pin:
            `<a target="_blank" rel="noopener noreferrer" href="https://www.zenithbank.com/personal-banking/etoken-app#step-1-search-for-zenith-etoken-app">Get the official Zenith eToken app</a>`,
        },
        fcmb: {
          pin:
            "This is the pin you use to make transactions on FCMB's mobile app",
        },
        access: {
          token:
            'You can get the code from your token device or generate a soft token from your bank app',
        },
        diamond: {
          token:
            'You can get the code from your token device or generate a soft token from your bank app',
        },
        wema: {
          pin:
            "This is the pin you use to make transactions on Alat's website and mobile app",
        },
      },
    };
  },
  methods: {
    getTooltip(inputType) {
      try {
        const { slug } = this.selectedAccount;
        if (slug === 'gtbank') {
          if (inputType === 'token') {
            return this.chargeData.amount <= 100000
              ? 'Check your hardware token for your code or dial <a href="tel:*737*7#">*737*7#</a>'
              : 'Check your hardware token for your code';
          } else if (inputType === 'secretAnswer') {
            return '';
          }
        }
        return this.helpfulInfo[slug][inputType];
      } catch (err) {
        return '';
      }
    },
    getInputValue(identifier) {
      const input = document.querySelector(`input.${identifier}`);
      if (!input) return '';
      return input.value;
    },
    async initiatePayment() {
      this.currentState = 'initiating';
      const { slug, accountNumber, username } = this.selectedAccount;
      const {
        amount,
        userId,
        name,
        transactionReference,
        isChargingDefaultAccount,
        signature,
      } = this.chargeData;
      const destinationAccountNumber =
        this.chargeData.destinationAccountNumber || '';
      const destinationBankCode = this.chargeData.destinationBankCode || '';
      const payoutType = isChargingDefaultAccount
        ? 'defaultAccount'
        : 'thirdParty';

      let error, responseBody;
      let requestBody = {
        accountNumber,
        slug,
        userId,
        amount,
        name,
        destinationAccountNumber,
        destinationBankCode,
        transactionReference,
        payoutType,
        username,
        signature,
      };

      if (this.bank.type === 'business') {
        requestBody.password = this.encryptedPassword;
        const { errorMessage, body } = await initiateBusinessPayment(
          requestBody
        );
        error = errorMessage;
        responseBody = body;
      } else {
        const { errorMessage, body } = await initiatePayment(requestBody);
        error = errorMessage;
        responseBody = body;
      }

      if (error) {
        if (responseBody && responseBody.errorCode === 'INPUT_INVALID') {
          this.$toasted.info('Session expired. Please reconnect your account.');
          this.$navigation.pop('Root');
        } else {
          this.showErrorPage(error);
        }
      } else {
        const { id: paymentId, requiredFields } = responseBody;

        this.currentState = 'approving';
        this.paymentId = paymentId;
        this.requiredFields = requiredFields;
      }
    },
    async approveDebit() {
      const fields = {};
      let error;

      for (let index = 0; index < this.requiredFields.length; index++) {
        const field = this.requiredFields[index];
        const value = this.getInputValue(field);

        if (value.length === 0) {
          error = `Your ${field} is required.`;
          break;
        }

        fields[field] = await encryptWithPublicKey(value, this.publicKey);
      }
      if (error) {
        this.$toasted.error(error);
        return;
      }

      this.currentState = 'debiting';
      approvePayment({
        paymentId: this.paymentId,
        password: this.selectedAccount.password,
        ...fields,
      });

      this.statusRefreshTimer = setInterval(
        this.performStatusRefresh,
        1000 * 5
      );
    },
    async performStatusRefresh() {
      const { code, status, requiredFields, errors } = await getPaymentStatus(
        this.paymentId
      );

      if (code >= 200 && code < 300) {
        if (status === 'Restricted') {
          this.showErrorPage(`Sorry, your account has been flagged and this transaction can not be completed. Please reach out to <a href="mailto:support@sendcashpay.com">support@sendcashpay.com</a>`)
        }
        else if (requiredFields.length === 0) {
          if (status === 'Completed') {
            this.endStatusRefresh();

            this.currentState = 'paid';
            this.paymentInitiatedRedirectTimer = setInterval(() => {
              --this.paymentInitiatedRedirectSecs;
              if (this.paymentInitiatedRedirectSecs <= 0) {
                clearInterval(this.paymentInitiatedRedirectTimer);
                this.finishPayment();
              }
            }, 1000);
            this.$emit('done');
          } else if (status === 'Failed') {
            const errorCode = errors[0].extensions.code;
            let errorMessage = null;

            if (errorCode === 'BANK_ERROR') errorMessage = errors[0].message;
            else errorMessage = 'Something went wrong. Please try again.';
            this.showErrorPage(errorMessage);
          } else if (status === 'FailedRetry') {
            this.endStatusRefresh();
            this.currentState = 'approving';

            const errorCode = errors[0].extensions.code;
            let errorMessage = null;
            
            if (errorCode === 'BANK_ERROR') errorMessage = errors[0].message;
            else errorMessage = 'Something went wrong. Please try again.';
            this.$toasted.error(errorMessage)
          }
        } else {
          this.endStatusRefresh();
          this.requiredFields = requiredFields;
          this.resetForm();
          this.currentState = 'finalize';
        }
      }
    },
    endStatusRefresh() {
      if (this.statusRefreshTimer) {
        clearInterval(this.statusRefreshTimer);
      }
    },
    finishPayment() {
      const payload = { paymentId: this.paymentId };
      if (this.currentState === 'paymentFailed') {
        payload.errorMessage = this.paymentError;
      }
      const data = JSON.stringify(payload);
      window.parent.postMessage(`sherlock:chargecomplete:${data}`, '*');
    },
    showErrorPage(errorMessage) {
      this.paymentId = null;
      this.paymentError = errorMessage;
      this.currentState = 'paymentFailed';
      this.redirectTimer = setInterval(() => {
        --this.redirectSecs;

        if (this.redirectSecs <= 0) {
          clearInterval(this.redirectTimer);
          this.finishPayment();
        }
      }, 1000);
    },
    async finalizePayment() {
      const fields = {};
      let error;

      for (let index = 0; index < this.requiredFields.length; index++) {
        const field = this.requiredFields[index];
        const value = this.getInputValue(field);

        if (value.length === 0) {
          error = `Your ${field} is required.`;
          break;
        }

        fields[field] = await encryptWithPublicKey(value, this.publicKey);
      }

      if (error) {
        this.$toasted.error(error);
        return;
      }

      this.currentState = 'finalizing';
      const { success, errorMessage, body } = await finalizePayment({
        paymentId: this.paymentId,
        ...fields,
      });

      if (!success || (body && body.status && body.status === 'Failed')) {
        this.showErrorPage(errorMessage);
      } else {
        this.currentState = 'paid';
        this.$emit('done');
      }
    },
  },
  mounted() {
    this.$navigation.register({
      modal: 'Root',
      page: 'Pay',
      push: () => {},
      pop: () => this.$root.$emit('continue', { page: 'accounts' }),
    });
    this.initiatePayment();
  },
};
</script>

<style lang="scss" scoped></style>
