<template>
  <main class="root container">
    <div class="modal modal-full direction-column pt" :class="{ 'modal--consent': page === 'consent' }">
      <div class="modal__page mb-s">
        <header v-if="!paymentInitiated" class="modal__header">
          <div class="flex align-center" v-if="page !== 'consent'">
            <img
              :src="meta.siteLogo"
              ref="brandLogo"
              :height="logoHeight"
              width="auto"
              :alt="`${meta.siteName || ''} logo`"
              v-if="!canGoBack"
              v-show="page !== 'accounts'"
            />

            <button
              v-show="canGoBack"
              :disabled="!canGoBack"
              class="icon-button lg"
              @click="$navigation.pop('Root')"
            >
              <img src="../assets/back.svg" />
            </button>
          </div>

          <button class="icon-button icon-button--close lg ml-auto" @click="close">
            <Cancel/>
          </button>
          
        </header>

        <consent v-if="page === 'consent'" />
        <accounts v-if="page === 'accounts'" />
        <pay v-if="page === 'pay'" @done="onPaymentDone" />
        <bank-select v-if="page === 'bank_select'" />
        <provide-credentials v-if="page === 'provide_credentials'" />
        <pre-setup v-if="page === 'pre_setup'" />
        <post-setup v-if="page === 'post_setup'" />
        <unlinked-accounts v-if="page === 'unlinked_accounts'" />
      </div>
      <a
        v-if="!meta.hideSendcashpayBadge"
        href="https://sendcashpay.com"
        target="_blank"
        rel="noopener noreferrer"
        class="mb sendcash-tag"
      >
        <img src="../assets/sendcash-tag.svg" />
      </a>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import Consent from '../components/Consent.vue';
import BankSelect from '../components/BankSelect.vue';
import ProvideCredentials from '../components/ProvideCredentials.vue';
import Accounts from '../components/Accounts.vue';
import Pay from '../components/Pay.vue';
import PreSetup from '../components/PreSetup.vue';
import PostSetup from '../components/PostSetup.vue';
import UnlinkedAccounts from '../components/UnlinkedAccounts.vue';
import Cancel from '../components/icons/cancel';
import { trackEvents } from '@/utilities/analytics';
import { CONNECT } from '../utilities/sdkContext';
import getDataAttributes from '../utilities/segment-data-attributes.js';

dayjs.extend(relativeTime);

export default {
  components: {
    Consent,
    BankSelect,
    ProvideCredentials,
    Accounts,
    Pay,
    PreSetup,
    PostSetup,
    UnlinkedAccounts,
    Cancel
  },
  computed: {
    ...mapGetters(['sdkContext', 'accounts', 'meta']),
  },
  data() {
    return {
      canGoBack: false,
      page: 'consent',
      paymentInitiated: false,
      logoHeight: 30,
    };
  },
  methods: {
    close() {
      trackEvents({
        type: 'action',
        properties: { event: 'closed modal' },
      });

      const getTimeStart = localStorage.getItem('timeStarted');
      let timeSpent = 0;
      if(getTimeStart) {
        const timeStart = dayjs(JSON.parse(getTimeStart));
        const timeEnd = dayjs();
        timeSpent = timeEnd.diff(timeStart, 's');
      }

      trackEvents({
        type: 'action',
        properties: { event: 'ended session', sessionDuration: timeSpent  },
      });

      if (this.sdkContext === CONNECT) {
        if (this.accounts.length > 0) this.closeConnectModal(this.accounts);
        else window.parent.postMessage('sherlock:exit', '*');
      } else window.parent.postMessage('sherlock:exit', '*');
    },
    gotoPage({ page }) {
      this.page = page;
    },
    onPaymentDone() {
      this.paymentInitiated = true;
    },
    trackAnalytics() {
      const body = document.querySelector('body');
      body.addEventListener('click', (event) => {
        const elementToTrack = event.target.hasAttribute('data-analytics-event')
          ? event.target
          : event.target.closest('[data-analytics-event]');
        if (elementToTrack) {
          const action = elementToTrack.getAttribute('data-analytics-event').toLowerCase();
          trackEvents({
            type: 'action',
            properties: {
              event: action?.toLowerCase(),
              ...getDataAttributes(elementToTrack),
            },
          });
        }
      });
    },
  },
  created() {
    this.trackAnalytics();
    trackEvents({
      type: 'action',
      properties: { event: 'opened modal' },
    });
    trackEvents({
      type: 'action',
      properties: { event: 'started session' },
    });

    const timeStart = dayjs();
    localStorage.setItem('timeStarted', JSON.stringify(timeStart));

    this.$navigation.setup({ modal: 'Root', context: this });
    this.$root.$on('continue', this.gotoPage);
  },
  mounted() {
    const brandLogo = this.$refs.brandLogo;

    brandLogo?.addEventListener('load', () => {
      switch (true) {
        case brandLogo.clientHeight >= brandLogo.clientWidth:
          this.logoHeight = 44;
          break;

        case brandLogo.clientWidth / brandLogo.clientHeight > 5.5:
          this.logoHeight = 25;
          break;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.root {
  height: 100vh;
  width: 100vw;
}

.list .icon {
  border-radius: 0% !important;
}

.align-center {
  align-content: center;
}

@media (max-width: 649px) {
  .modal {
    padding-top: 0;

    &__header {
      margin-bottom: 1.25rem;
    }
  }
}
</style>
